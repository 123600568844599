<template>

    <Dialog header='Invite your friends' v-model:visible="display" :modal="true" :closable="true" class="invite-friends-modal" @show="onShown">
        <AutoComplete ref='friendSearchBar' id='friendSearchBar' v-model="invitedFriends" :placeholder="searchBarPlaceholderText" 
            :suggestions="filteredFriends" @complete="searchFriends($event)" field="name" :multiple="true" @click="clearInput($event)"
            @item-select="itemSelected()" @item-unselect="itemUnselected()" panelClass="invite-friends-search-panel">
            <template #item="slotProps">
                <div style="display:flex; align-items: center">
                        <!-- <img class="profile-image" :src="(slotProps.item.avatar && slotProps.item.avatar !== undefined) ? slotProps.item.avatar : '../Default-Avatar.jpeg'" /> -->
                    <UserAvatar :imageSrc="slotProps.item.avatar" size="md" /> 
                    <div style='margin-left: 8px;'>
                        <span class='username'>@{{slotProps.item.name}}</span>
                        <span v-if="hasFullName(slotProps.item)" class="full-name">
                            ({{stringUtils.fullName(slotProps.item)}})
                        </span>
                    </div>
                </div>
            </template>
            <template #chip="slotProps">
                <div style="display:flex; align-items: center">
                        <!-- <img class="profile-image" :src="(slotProps.item.avatar && slotProps.item.avatar !== undefined) ? slotProps.item.avatar : '../Default-Avatar.jpeg'" /> -->
                    <UserAvatar :imageSrc="slotProps.value.avatar" size="sm" /> 
                    <div>
                        <span class='username'>@{{slotProps.value.name}}</span>
                        <span v-if="hasFullName(slotProps.value)" class="full-name">
                            ({{stringUtils.fullName(slotProps.value)}})
                        </span>
                    </div>
                </div>
            </template>
        </AutoComplete>

        <div class='share-external-link-section'>
            <span class='section-text'>For external invites:</span>
            <div class='share-external-link-container'>
                <InputText class='external-invite-input' type="text" :placeholder="externalLink"/>
                <Button label='Copy link' class='get-shareable-link-button' @click='copyInviteLink'/>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel" class='cancel-button' @click='cancel'/>
            <Button label='Finish' class='finish-button' @click='finish' />
        </template>
    </Dialog>
</template>

<script>
import {appProtocol, APP_DOMAIN_PREFIX, APP_URL, GROUP_DOMAIN_SUFFIX} from '../../../common/config';//eslint-disable-line
import AutoComplete from 'primevue/autocomplete';
import Dialog from 'primevue/dialog';
import UserAvatar from '../../profile/UserAvatar.vue';

import GroupService from '../../../service/GroupService';
import UserService from '../../../service/UserService';
import StringUtils from '../../../utilities/StringUtils';

import { mapState } from 'vuex';
import Mark from 'mark.js';

export default {
    name: 'InviteFriendsModal',
    data() {
        return {
            display: false,
            searchBarPlaceholderText: 'Invite other investors on INVRS (optional)',
            externalLink: null,
            selectedFriends: [],
            invitedFriends: [],
            filteredFriends: [],
            stringUtils: StringUtils
        }
    },
    computed: {
        ...mapState(['selectedGroup'])
    },

    components: {
        AutoComplete, Dialog, UserAvatar
    },

    methods: {
        hasFullName(analyst){
            return analyst.firstName || analyst.lastName;
        },

        copyInviteLink() {
            var tempInput = document.createElement('input');
            tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
            tempInput.value = this.externalLink;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);

            this.$toast.add({ severity: 'success', summary: 'External invite link copied!', detail: '', life: 2000, group: 'tc' });
        },

        open() {
            this.display = true;
            GroupService.getGroupInviteLinks(this.selectedGroup.groupId, 1, 0, null).then((resp) => {
                this.externalLink = this.createInviteLink(resp.data.invites[0].inviteCode);
            })
        },
        createInviteLink(inviteCode) {
            //if( APP_DOMAIN_PREFIX == 'dev.') {
               return APP_URL+"/index.html#/groupPreview?invite="+inviteCode;
                
            //}
            //else {
            //    return appProtocol+this.selectedGroup.name.replaceAll(" ", "_").toLowerCase()+GROUP_DOMAIN_SUFFIX+"/index.html#/groupPreview?invite="+inviteCode;
            //}
            
        },
        cancel() {
            this.display = false;
        },
        onShown() {
            // If we selected more than one person to invite, we simply hide the placeholder text
            if (this.invitedFriends.length > 0) {

                document.getElementById('friendSearchBar').setAttribute('placeholder', '');
            }
        },
        finish() {
            GroupService.inviteToGroup(this.selectedGroup.groupId, this.invitedFriends, null).then((resp) => {
                this.cancel();
                this.invitedFriends = [];
                this.selectedFriends = [];

                if (resp.data.status === 'success') {
                    this.$toast.add({ severity: 'success', summary: 'Successfully invited your friends!', detail: '', life: 2000, group: 'tc' });
                } else {
                    console.log(resp);
                }
            })
        },

         searchFriends(evt) {
            UserService.queryAnalysts(evt.query, 10, 0, true).then((resp) => {
                
                // We don't want to include analysts in the search results that are either you or is of a group type
                const people = resp.data.analysts.filter((analyst) => {
                    let isNotInvited = true;

                    if (this.$store.state.users.user.analyst.name !== analyst.name && analyst.userTypeId === 1) {
                        this.invitedFriends.forEach((invited) => {
                            if (invited.name === analyst.name) {
                                isNotInvited = false;
                                return;
                            }
                        })
                    } else {
                        isNotInvited = false;
                    }

                    return isNotInvited;
                })

                this.filteredFriends = people;

                    // Highlighting of matches in results
                    this.$nextTick(() => {
                        let instance = new Mark(document.querySelectorAll('.invite-friends-search-panel .p-autocomplete-item'));

                        const regex = new RegExp(`\\b${evt.query}|(?<=[@(])\\b${evt.query}`, 'gi');

                        instance.unmark();
                        instance.markRegExp(regex, {
                            'className': 'search-match-highlight',
                            "filter": function(textNode, foundTerm, totalCounter){ //eslint-disable-line
                                return true;
                            },
                        });
                    })
                
            })
        },

        itemSelected() {
            document.getElementById('friendSearchBar').setAttribute('placeholder', '');
        },
        itemUnselected() {
            // We should only show the placeholder text if we haven't selected any friends yet
            if (this.invitedFriends.length === 0) {
                document.getElementById('friendSearchBar').setAttribute('placeholder', this.searchBarPlaceholderText);
            }
        },
        clearInput(e) {
			e['query'] = '';
            this.$refs.friendSearchBar.$emit('complete', e)
			this.$refs.friendSearchBar.show();
        },
    }
}
</script>

<style>
#friendSearchBar {
    font-family: "Trebuchet MS", "Verdana";
}

.invite-friends-modal {
    width: 800px;
}

.invite-friends-modal .p-dialog-title {
    color: #32364e;
}

.invite-friends-modal .p-dialog-header {
    padding-bottom: 0px;
} 

.invite-friends-modal .p-dialog-content {
    padding: 32px 16px;
    overflow-y: hidden;
}

.invite-friends-modal .p-dialog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
}
</style>
<style scoped>
::v-deep(*:not([class*=material-icons], .pi)),
*:not([class*=material-icons], .pi) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.p-autocomplete.p-autocomplete-multiple {
    width: 100%;
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container) {
    padding: 0;
    border: none;
    width: 100%;
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 8px 8px 8px 32px;
    max-height: 98px;
    overflow-y: auto;
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container::-webkit-scrollbar) {
	/* width */
	width: 10px;
    
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container::-webkit-scrollbar-thumb) {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container .p-autocomplete-input-token) {
    padding: 0;
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container:not(.p-disabled).p-focus) {
    box-shadow: none;
}
.p-autocomplete.p-autocomplete-multiple ::v-deep(.p-autocomplete-multiple-container .p-autocomplete-token) {
    padding: 4px;
    margin: 0px 8px 4px 0px;
    background: #FFFFFF;
    border: 1px solid #33CC99;
    color: #32364e;
}
.p-autocomplete-panel.p-component .p-autocomplete-item:not(:last-child),
.p-autocomplete-panel.p-component .p-autocomplete-item-group:not(:last-child) {
    border-bottom: 1px solid #ddd;;
}
.p-autocomplete-panel.p-component .p-autocomplete-item .username {
    color: black;
}
.p-autocomplete-panel.p-component .p-autocomplete-item:hover .username {
    color: #FFFFFF;
}
.p-autocomplete-panel.p-component .p-autocomplete-item .full-name {
  font-weight: bold;
  color: #32364e;
  line-height: 1.2;
  margin-left: 5px;
}
.p-autocomplete-panel.p-component .p-autocomplete-item:hover .full-name {
  color: #a6a6a6;
}
::v-deep(#friendSearchBar) {
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
::v-deep(#friendSearchBar:enabled:focus) {
    border-color: #33CC99;
}


.share-external-link-section {
    margin-top: 16px;
}

.share-external-link-section .section-text {
    color: #32364e;
    font-size: 16px;    
}

.share-external-link-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
}


.external-invite-input {
    pointer-events: none;
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    /* padding: 8px 8px 8px 36px; */
    font-size: 16px;
	flex: 1;
    margin-right: 16px;
}

.get-shareable-link-button {
    border: 1px solid #33CC99;
    background: #FFFFFF;
    color: #32364e;
    flex: 0 1 150px;
    height: 32px;
}
.get-shareable-link-button:hover {
    border-color: #33CC99;
    background: #33CC99;
    color: #FFFFFF;
}
.get-shareable-link-button:focus {
    border-color: #33CC99;
}

.invite-friends-modal .p-dialog-footer button {
    width: 50%;
    margin: 0px;
}

.invite-friends-modal .p-dialog-footer .cancel-button {
    border: 1px solid #32364e;
    background: #FFFFFF;
    color: #32364e;
    margin-right: 24px;
}
.invite-friends-modal .p-dialog-footer .cancel-button:hover {
    border-color: #32364e;
    color: #FFFFFF;
    background: #32364e;
}
.invite-friends-modal .p-dialog-footer .cancel-button:focus {
    border-color: #32364e;
}
.invite-friends-modal .p-dialog-footer .finish-button {
    border: 1px solid #33CC99;
    background: #33CC99;
    color: #FFFFFF;
}
.invite-friends-modal .p-dialog-footer .finish-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.invite-friends-modal .p-dialog-footer .finish-button:focus {
    border-color: #33CC99;
}
</style>